<template>
    <div>
        <!-- 主体内容	 -->
        <el-card>
          <el-breadcrumb>
            <el-breadcrumb-item :to="{ path: '/' }">
              <span class="nav_path"></span>首页
            </el-breadcrumb-item>
            <el-breadcrumb-item>称重单管理</el-breadcrumb-item>
            <el-breadcrumb-item>称重单列表</el-breadcrumb-item>
          </el-breadcrumb>

          <div class="search_area" style="display: inline-block;">
            <div style="display: inline;">
              <el-input placeholder="输入车牌号" v-model="queryCondition.query" class="input-with-select" clearable
                        @clear="getWeighList" style="width: 320px">
                <el-button slot="append" icon="el-icon-search" @click="getWeighList"></el-button>
              </el-input>
            </div>
          </div>

          <div style="text-align: left;margin: 50px 3px 10px;white-space: nowrap;">
            <div style="display: inline;">
              <span style="font-size: 12px;font-weight: bolder">收货单位：</span>
              <el-select v-model="queryCondition.accept" clearable placeholder="请选择" size="small" @change="getWeighList">
                <el-option
                    v-for="item in acceptSet"
                    :key="item.index"
                    :label="item"
                    :value="item"
                />
              </el-select>
            </div>

            <div style="margin-left: 30px;display: inline;">
              <span style="font-size: 12px;font-weight: bolder">货物名称：</span>
              <el-select v-model="queryCondition.goodName" clearable placeholder="请选择" size="small" @change="getWeighList">
                <el-option
                    v-for="item in goodNameSet"
                    :key="item.index"
                    :label="item"
                    :value="item"
                />
              </el-select>
            </div>

            <div style="margin-left: 30px;display: inline;">
              <span style="font-size: 12px;font-weight: bolder">起止日期：</span>
              <el-date-picker
                  v-model="queryCondition.date"
                  type="daterange"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  format="yyyy-MM-dd"
                  @change="getWeighList"
                  size="small"
              />
            </div>
          </div>

          <div style="text-align: left;margin-left: 3px;white-space: nowrap;">
            <div style="display: inline;">
              <span style="font-size: 12px;font-weight: bolder">完成状态：</span>
              <el-button-group>
                <el-button size="small" :type="isClickedStatus(0)" @click="getWeighListByStatus(0)">全部</el-button>
                <el-button size="small" :type="isClickedStatus(1)" @click="getWeighListByStatus(1)">未完成</el-button>
                <el-button size="small" :type="isClickedStatus(2)" @click="getWeighListByStatus(2)">已完成</el-button>
              </el-button-group>
            </div>

            <div style="margin-left: 55px;display: inline;">
              <span style="font-size: 12px;font-weight: bolder">审核状态：</span>
              <el-button-group>
                <el-button size="small" :type="isClickedReview(0)" @click="getWeighListByReview(0)">全部</el-button>
                <el-button size="small" :type="isClickedReview(1)" @click="getWeighListByReview(1)">未审核</el-button>
                <el-button size="small" :type="isClickedReview(2)" @click="getWeighListByReview(2)">已审核</el-button>
              </el-button-group>
            </div>

            <div style="margin-left: 115px;display: inline;"> <!--  style="position: absolute;right: 40px" -->
              <el-button size="small" type="primary" icon="el-icon-download" @click="downloadExcel()">导出Excel</el-button>
            </div>

            <div style="float: right;display: inline;">
                <el-button size="small" type="danger" icon="el-icon-delete" @click="batchDelete" :disabled="this.selection.length === 0">批量删除</el-button>
            </div>
          </div>


            <!-- 列表用户内容区域	 -->
            <el-table :data="weighList" :header-cell-style="{background: '#F6F6F6',fontWeight: '600',color: '#333',}"
                      style="width: 100%;border-top: 1px solid #f2f2f2;margin-top: 20px" @selection-change="handleSelectionChange" ref="multipleTable">
                <el-table-column type="selection" width="50">
                </el-table-column>
                <el-table-column prop="weighId" align="center" label="id" min-width="50">
                </el-table-column>
                <el-table-column prop="weighCar" align="center" label="车牌号" min-width="85">
                </el-table-column>
                <el-table-column prop="weighDelivery" align="center" label="发货单位" min-width="100" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="weighAccept" align="center" label="收货单位" min-width="100" show-overflow-tooltip>
                </el-table-column>
<!--                <el-table-column prop="weighDriver" align="center" label="司机" min-width="70">-->
<!--                </el-table-column>-->
                <el-table-column prop="weighGoods" align="center" label="货物名称" min-width="100" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="weighPrice" align="center" label="货物数量" min-width="70">
                </el-table-column>
                <el-table-column prop="weighPrice" align="center" label="货物价格" min-width="70">
                </el-table-column>
                <el-table-column prop="weighFirst" align="center" label="首次重量" min-width="70">
                </el-table-column>
                <el-table-column prop="weighSecond" align="center" label="二次重量" min-width="70">
                </el-table-column>
                <el-table-column prop="weighLast" align="center" label="净重" min-width="70">
                </el-table-column>
                <el-table-column prop="picture" align="center" label="图片" min-width="50">
                  <template #default="scope">
                    <el-image v-if="scope.row.picture!=null" :src="previewPic(scope.row.picture)" :preview-src-list="previewPicList(scope.row.picture)">
                    </el-image>
                  </template>
                </el-table-column>
                <el-table-column prop="weighFirTime" align="center" label="第一次时间" min-width="140" :formatter="TimeFormatter">
                </el-table-column>
                <el-table-column prop="weighSecTime" align="center" label="第二次时间" min-width="140" :formatter="TimeFormatter">
                </el-table-column>
                <el-table-column prop="weighStatus" align="center" label="状态" min-width="90" :formatter="RoleFormatter">
                  <template #default="scope">
                    <el-tag v-if="scope.row.weighStatus === 0" type="info">待称重</el-tag>
                    <el-tag v-else-if="scope.row.weighStatus === 1">首次称重{{scope.row.isReview}}</el-tag>
                    <el-tag v-else-if="scope.row.weighStatus === 2" type="success">称重完成</el-tag>
                  </template>
                </el-table-column>
                <el-table-column prop="verified" align="center" label="审核" min-width="90" :formatter="VerifyFormatter">
                  <template #default="scope">
                    <el-tag v-if="scope.row.verified === 0 && scope.row.weighStatus !== 2" type="info" round=true>无需审核</el-tag>
                    <el-tag v-else-if="scope.row.verified === 0 && scope.row.weighStatus === 2" type="info">待审核</el-tag>
                    <el-tag v-else-if="scope.row.verified === 1" type="success">通过</el-tag>
                    <el-tag v-else-if="scope.row.verified === 2" type="danger">不通过</el-tag>
                  </template>
                </el-table-column>
                <el-table-column fixed="right" prop="address" align="center" label="操作" min-width="250">
                    <template slot-scope="scope">
                          <el-button class="primary" type="text" :disabled="scope.row.verified === 1" @click="editweighList(scope.row)">编辑</el-button>
                        <span class="separator">|</span>
                          <el-button class="primary" :disabled="!(scope.row.weighStatus===2 && scope.row.verified===0)" type="text" @click="showVerify(scope.row)">审核</el-button>
                        <span class="separator">|</span>
                          <el-button class="primary" type="text" :disabled="scope.row.verified === 0" @click="editWeighDialog(scope.row), print()" v-print="printMe">打印</el-button>
                        <span class="separator">|</span>
                          <el-button class="primary" type="text" @click="showHistory(scope.row)">历史</el-button>
                        <span class="separator">|</span>
                          <el-button class="danger" type="text" @click="deleteWeigh(scope.row.weighId)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页功能 -->
            <el-pagination align="left" @size-change="SizeChange" @current-change="CurrentChange"
                           :disabled="false"
                           :current-page="queryCondition.pagenum"
                           :page-sizes="[queryCondition.pagesize]" :page-size="queryCondition.pagesize"
                           layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </el-card>

        <!-- 修改称重单diaolog对话框 -->

        <el-dialog align="left" title="修改称重单" :visible.sync="editWeighVisible" @close="closeDialog" width="800px" v-bind:class="{show_1:form_1}">
            <!--主体部分 -->
            <el-form :model="editWeighForm" status-icon :rules="editWeighFormRul" ref="editWeighFormRel" label-width="100px"
                     class="demo-ruleForm">
                <el-row>
                    <el-col :span="10">
                        <el-form-item label="车牌号" prop="weighCar">
                            <el-input v-model="editWeighForm.weighCar" autocomplete="off" disabled></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="货物名称" prop="weighGoods">
                            <el-input v-model="editWeighForm.weighGoods"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="10">
                        <el-form-item label="货物数量" prop="weighSpec">
                            <el-input v-model="editWeighForm.weighSpec" autocomplete="off"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="发货单位" prop="weighDelivery">
                            <el-input v-model="editWeighForm.weighDelivery"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="10">
                        <el-form-item label="收货单位" prop="weighAccept">
                            <el-input v-model="editWeighForm.weighAccept" autocomplete="off"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="司机" prop="weighDriver">
                            <el-input v-model="editWeighForm.weighDriver"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="10">
                        <el-form-item label="货物价格" prop="weighPrice">
                            <el-input v-model="editWeighForm.weighPrice" autocomplete="off"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="首次称重" prop="weighFirst">
                            <el-input :disabled="role !== '1'" v-model="editWeighForm.weighFirst"><i slot="suffix" style="font-style: normal;margin-right: 8px">千克</i></el-input>
<!--                            <el-input v-else disabled v-model="editWeighForm.weighFirst"><i slot="suffix" style="font-style: normal;margin-right: 8px">千克</i></el-input>-->
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="10">
                        <el-form-item label="二次称重" prop="weighSecond">
<!--                          <el-input v-if="role === 1" v-model="editWeighForm.weighSecond" autocomplete="off"><i slot="suffix" style="font-style: normal;margin-right: 8px">千克</i></el-input>-->
                          <el-input :disabled="role !== '1'" v-model="editWeighForm.weighSecond" autocomplete="off"><i slot="suffix" style="font-style: normal;margin-right: 8px">千克</i></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="净重">
                            <el-input v-model="editWeighForm.weighLast" disabled><i slot="suffix" style="font-style: normal;margin-right: 8px">千克</i></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="11">
                        <el-form-item></el-form-item>
                    </el-col>
                    <el-col :span="11">
                        <el-form-item class="btn_printer ">
<!--                            <el-button v-if="editWeighForm.verified===1" @click="display_form(), editWeighDialog(), print()" v-print="printMe" icon="el-icon-printer">打印</el-button>-->
<!--                            <el-button v-else @click="no_print()" icon="el-icon-printer">打印</el-button>-->
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="editWeighVisible = false">取 消</el-button>
              <el-button type="primary" @click="editPick(editWeighForm.verified)" style="background-color: #42b7fb;border-color: #42b7fb">确 定</el-button>
<!--              <el-button type="primary" @click="editPick(editWeighForm.verified)" style="background-color: #ff4d51;border-color: #ff4d51">审 核</el-button>-->
            </span>
        </el-dialog>

        <el-dialog align="left" :visible.sync="editWeighVisible_2" width="50%" v-bind:class="{show_2:form_2}">
            <!--主体部分 -->
            <el-form :model="editWeighForm" status-icon label-width="100px"
                      id="printMe" style="border: 1px solid white;">
              <div class="print_form" v-for="i in 3">
                <div class="title">
                    <p>{{global.tableTitle}}</p>
                    <p>称重计量单</p>
                </div>
                <div class="print-time">
                  <p>打印时间: {{FormatDate(new Date())}}</p>
                </div>
                <el-row>
                    <el-col :span="11">
                      <el-form-item>
                        <span slot="label">
                          <span class="print_item w3"><strong>车牌号</strong></span>
                        </span>
                        <el-input v-model="editWeighForm.weighCar" readonly></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="11" class="print_col">
                      <el-form-item>
                            <span slot="label">
                              <span class="print_item w2"><strong>首</strong></span>
                              <span class="print_item"><strong>重</strong></span>
                            </span>
                        <el-input v-model="editWeighForm.weighFirst" readonly><i slot="suffix" class="kg">千克</i></el-input>
                      </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                  <el-col :span="11">
                    <el-form-item>
                        <span slot="label">
                          <span class="print_item"><strong>发货单位</strong></span>
                        </span>
                      <el-input v-model="editWeighForm.weighDelivery" readonly></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="11" class="print_col">
                    <el-form-item>
                        <span slot="label">
                          <span class="print_item w2"><strong>次</strong></span>
                          <span class="print_item"><strong>重</strong></span>
                        </span>
                      <el-input v-model="editWeighForm.weighSecond" autocomplete="off" readonly><i slot="suffix" class="kg">千克</i></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                    <el-col :span="11">
                        <el-form-item>
                            <span slot="label">
                              <span class="print_item"><strong>收货单位</strong></span>
                            </span>
                            <el-input v-model="editWeighForm.weighAccept" autocomplete="off" readonly></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11" class="print_col">
                      <el-form-item>
                            <span slot="label">
                              <strong><span class="print_item w2">净</span></strong>
                              <strong><span class="print_item">重</span></strong>
                            </span>
                        <el-input v-model="editWeighForm.weighLast" readonly><i slot="suffix" class="kg">千克</i></el-input>
                      </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="11">
                      <el-form-item>
                          <span slot="label">
                            <span class="print_item"><strong>货物名称</strong></span>
                          </span>
                        <el-input v-model="editWeighForm.weighGoods" readonly></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="11" class="print_col">
                        <el-form-item>
                          <span slot="label">
                            <span class="print_item"><strong>货物价格</strong></span>
                          </span>
                          <el-input v-model="editWeighForm.weighPrice" autocomplete="off" readonly></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="7">
                      <el-form-item>
                        <span slot="label">
                          <span class="print_item w3"><strong>司磅员</strong></span>
                        </span>
                        <el-input readonly></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="7" class="print_col_bottom">
                      <el-form-item>
                          <span slot="label">
                            <span class="print_item w3"><strong>监磅员</strong></span>
                          </span>
                        <el-input readonly></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="7" class="print_col_bottom">
                      <el-form-item>
                            <span slot="label">
                              <span class="print_item w2"><strong>经</strong></span>
                              <span class="print_item"><strong>理</strong></span>
                            </span>
                        <el-input readonly></el-input>
                      </el-form-item>
                    </el-col>
                </el-row>
              </div>
            </el-form>
        </el-dialog>

      <el-dialog align="left" title="称重单审核" :model="unReviewList" :visible.sync="verifiedVisible" @close="closeReviewDialog()" width="450px">
<!--        <span>是否审核通过该称重单？</span>-->
        <div v-if="unReviewList != null">
          <p style="font-weight: bolder">审核内容</p>
          <div style="border: 1px solid #999">
            <p style="text-indent: 2em">{{unReviewList.content}}</p>
            <p style="text-align: right;font-size: 12px;color: #999;margin-right: 15px">提交人：{{unReviewList.operator}}</p>
            <p style="text-align: right;font-size: 12px;color: #999;margin-right: 15px">{{FormatDate(unReviewList.created)}}</p>
          </div>
        </div>
        <div>
          <p style="font-weight: bolder">审核意见</p>
          <textarea rows="10" cols="55" v-model="comment"></textarea>
        </div>
        <span slot="footer" class="dialog-footer">
      		<el-button @click="verifiedVisible = false">取 消</el-button>
      		<el-button type="danger" @click="verify(2, unReviewList.id)">不通过</el-button>
      		<el-button type="success" @click="verify(1, unReviewList.id)" style="background: #42b7fb;color: #fff;border: none">通 过</el-button>
      	</span>
      </el-dialog>

      <el-dialog align="left" title="历史记录" :visible.sync="historyVisible" @close="closeHistoryDialog" width="450px">
        <el-scrollbar style="height: 400px" wrap-style="overflow-x:hidden;" always="true">
          <el-timeline>
            <el-timeline-item v-if="histories.length==0">
              <p>
                暂无记录
              </p>
            </el-timeline-item>
            <el-timeline-item
                v-for="(history, index) in histories"
                :key="index"
                size="large"
                :icon="getIcon(history)"
                :color="getColor(history)"
                v-else
            >
              <label>{{getStatus(history)}}</label>
              <p>
                <label style="font-weight: bolder">提交内容：</label>
                <span style="color: #999">{{ history.content }}</span>
              </p>
              <p>
                <label style="font-weight: bolder">提交人：</label>
                <span style="color: #999;">{{ history.operator }}</span>
              </p>
              <p>
                <label style="font-weight: bolder">提交时间：</label>
                <span style="color: #999;">{{ FormatDate(history.created) }}</span>
              </p>
              <div v-if="history.isReview==1">
                <div v-if="history.reviewData==null">
                  <label style="font-weight: bolder">审核：</label>
                  <span style="color: #999;">待审核</span>
                </div>
                <div v-else>
                  <p>
                    <label style="font-weight: bolder">审核意见：</label>
                    <span style="color: #999;">{{ history.reviewData.comment }}</span>
                  </p>
                  <p>
                    <label style="font-weight: bolder">审核人：</label>
                    <span style="color: #999">{{ history.reviewData.reviewer }}</span>
                  </p>
                  <p>
                    <label style="font-weight: bolder">审核时间：</label>
                    <span style="color: #999;">{{ FormatDate(history.reviewData.time) }}</span>
                  </p>
                </div>
              </div>
              <div v-else>
<!--                <label style="font-weight: bolder">审核：</label>-->
<!--                <span style="color: #999;">无需审核</span>-->
              </div>
            </el-timeline-item>
          </el-timeline>
        </el-scrollbar>
<!--        <span slot="footer" class="dialog-footer">-->
<!--      		<el-button @click="historyVisible = false">关 闭</el-button>-->
<!--      	</span>-->
      </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                role: '',
                queryCondition: {
                  query: null,
                  pagenum: 1,
                  pagesize: 8,
                  date: null,
                  goodName: null,
                  accept: null,
                  status: 0,
                  review: 0
                },
                acceptSet: [],
                goodNameSet: [],
                imageUrl: this.$http.defaults.baseURL + "image/",
                //称重单列表参数
                queryInfo: {
                    query: '',
                    pagenum: 1,
                    pagesize: 8
                },
                comment: "",
                unReviewList: {},
                histories: [],
                //称重单列表
                weighList: null,
                //总的数据条数
                total: 0,
                editWeighFormRul: {
                  weighDelivery: [
                    {
                      required: true,
                      message: '请输入发货单位',
                      trigger: 'blur'
                    }],
                  weighAccept: [
                      {
                          required: true,
                          message: '请输入收货单位',
                          trigger: 'blur'
                      }],
                  weighGoods: [
                    {
                      required: true,
                      message: '请输入货物名称',
                      trigger: 'blur'
                    }],
                  // weighCar: [
                  //   {
                  //     required: true,
                  //     message: '请输入车牌号',
                  //     trigger: 'blur'
                  //   },
                  //   // {
                  //   //   pattern: /(^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z][A-Z0-9]{5}[挂学警港澳]?$)/,
                  //   //   message: '车牌号码有误',
                  //   //   trigger: 'change'
                  //   // }
                  // ],
                  weighPrice: [
                    {
                      pattern: /(^\d*(\.\d{1,4})?$)/,
                      message: '请输入正确的货物价格',
                      trigger: 'change'
                    }
                  ],
                  weighSpec: [
                    {
                      pattern: /(^\d*(\.\d{1,4})?$)/,
                      message: '请输入正确的货物数量',
                      trigger: 'change'
                    }
                  ],
                  weighFirst: [
                    {
                      pattern: /(^\d*(\.\d{1,4})?$)/,
                      message: '请输入正确的重量',
                      trigger: 'change'
                    }
                  ],
                  weighSecond: [
                    {
                      pattern: /(^\d*(\.\d{1,4})?$)/,
                      message: '请输入正确的重量',
                      trigger: 'change'
                    }
                  ]
                },
                //修改称重单参数
                editWeighForm: {
                    weighId: '',
                    weighCar: '',
                    weighDelivery: '',
                    weighAccept: '',
                    weighDriver: '',
                    weighGoods: '',
                    weighPrice: '',
                    weighFirst: '',
                    weighSecond: '',
                    weighLast: '',
                    weighFirTime: '',
                    weighSecTime: '',
                    weighSpec:'',
                    verified: '',
                    weighStatus: ''
                },
              verifyItem: {
                weighId:'',
                reviewStatus: '',
                historyId: '',
                reviewComment: ''
              },
                //修改称重单dialog,控制显示隐藏
                editWeighVisible: false,
                editWeighVisible_2: false,
                form_1: false,
                form_2: true,
                verifiedVisible: false,
                historyVisible: false,
                printMe: {
                    id: 'printMe',
                    beforeOpenCallback(vue) {
                        vue.editWeighVisible_2 = false;
                        vue.form_1 = false;
                        vue.form_2 = true;
                    }
                },
                selection: []
            }
        },
        mounted() {
          this.$data.role = window.sessionStorage.getItem('role');
          this.getWeighList();
          this.getDeliveries();
          this.getGoodNames();
        },
        methods: {
            //请求称重单列表
            getWeighList() {
                this.$http.post('weigh_query', this.queryCondition).then(res => {
                  // console.log(this.queryCondition)
                  // console.log(res.data)
                    if (res.data.code !== 200) return this.$message.error("查询失败！" + res.data.msg);
                    this.weighList = res.data.data.records;
                    this.total = res.data.data.total;
                })
            },
            getWeighListByStatus(value) {
              this.queryCondition.status = value;
              this.getWeighList();
            },
            getWeighListByReview(value) {
              this.queryCondition.review = value;
              this.getWeighList();
            },
            isClickedStatus(value) {
              if (value === this.queryCondition.status) {
                return "primary"
              }else {
                return "none"
              }
            },
            isClickedReview(value) {
              if (value === this.queryCondition.review) {
                return "primary"
              }else {
                return "none"
              }
            },
            getGoodNames() {
              this.$http.post('getGoodNames').then(res => {
                if (res.data.code !== 200) return this.$message.error("查询失败！" + res.data.msg);
                this.goodNameSet = res.data.data;
              })
            },
            getDeliveries() {
              this.$http.post('getAccepts').then(res => {
                if (res.data.code !== 200) return this.$message.error("查询失败！" + res.data.msg);
                this.acceptSet = res.data.data;
              })
            },
            //格式化时间
            TimeFormatter(row, col) {
                let date = row[col.property]
                if (date === undefined || date === null) {
                    return '__'
                }
                let year = date[0],
                    month = ("0" + date[1]).slice(-2),
                    d = ("0" + date[2]).slice(-2),
                    hour = ("0" + date[3]).slice(-2),
                    minute = ("0" + date[4]).slice(-2),
                    second = ("0" + date[5]).slice(-2);
                return year + "-" + month + "-"+ d + " " + hour + ":" + minute + ":" + second;
            },
            FormatDate(date) {
                const moment = require('moment');
                if (date === undefined || date === '') {
                    return ''
                }
                date[1] = date[1] - 1
                return moment(date).format('yyyy-MM-DD HH:mm:ss')
            },
            // TimeFormatter(row, column) {
            //     const moment = require('moment');
            //     const date = row[column.property];
            //
            //     if (date == undefined) {
            //         return ''
            //     }
            //
            //     date[1] = date[1] - 1
            //     return moment(date).format('yyyy-MM-DD HH:mm:ss')
            //
            // },
            // FormatDate(date) {
            //     const moment = require('moment');
            //     if (date == undefined || date == '') {
            //         return ''
            //     }
            //     date[1] = date[1] - 1
            //     return moment(date).format('yyyy-MM-DD HH:mm:ss')
            // },
            RoleFormatter(row, column) {
                if (row.weighStatus === 0) {
                    return "待称重"
                } else if (row.weighStatus === 1) {
                    return "首次称重"
                } else if (row.weighStatus === 2) {
                    return "称重完成"
                }
            },
            VerifyFormatter(row, column) {
              if (row.verified === 0) {
                return "待审核"
              } else if (row.verified === 1) {
                return "通过"
              } else if (row.verified === 2) {
                return "不通过"
              }
            },
            //每页数据条数改变时触发
            SizeChange(newval) {
                this.queryCondition.pagesize = newval;
                this.getWeighList();
            },
            //当前页面改变时触发
            CurrentChange(newval) {
                this.queryCondition.pagenum = newval;
                this.getWeighList();
            },
            //修改称重单信息事件
            editweighList(item) {
                this.editWeighVisible = !this.editWeighVisible;
                this.editWeighForm = {
                    weighId: item.weighId,
                    weighCar: item.weighCar,
                    weighDelivery: item.weighDelivery,
                    weighAccept: item.weighAccept,
                    weighDriver: item.weighDriver,
                    weighGoods: item.weighGoods,
                    weighPrice: item.weighPrice,
                    weighFirst: item.weighFirst,
                    weighSecond: item.weighSecond,
                    weighLast: item.weighLast,
                    weighSpec:item.weighSpec,
                    verified: item.verified
                }
            },
          showVerify(item) {
              console.log(item.weighGoods);
              if (item.weighGoods === null || item.weighAccept === null || item.weighDelivery === null) {
                this.$message.warning("请先完善称重单信息！");
                return;
            }
            this.verifiedVisible = !this.verifiedVisible;
            this.verifyItem.weighId = item.weighId;
            this.showUnReview(item);
          },
            //点击确定发送请求称重单信息
            editPick(verified) {
              if (verified === 1) {
                this.$message.warning("审核通过的过磅单无法再修改！");
                return;
              }
              this.$refs.editWeighFormRel.validate(valid => {
                if (!valid) return;
                this.$http.post('weigh_update', this.editWeighForm).then(res => {
                  if (res.data.code !== 200) return this.$message.error("修改失败！" + res.data.msg);
                  this.$message.success("修改成功！");
                  this.getWeighList();
                  this.editWeighVisible = !this.editWeighVisible;
                })
              })
            },
          verify(status, historyId) {
            if (this.comment.length === 0) {
              this.$message.warning("审核意见不能为空");
              return;
            }
              this.verifyItem.reviewStatus = status;
              this.verifyItem.historyId = historyId;
              this.verifyItem.reviewComment = this.comment;
              this.$http.post('verify', this.verifyItem).then(res => {
              if (res.data.code !== 200) return this.$message.error("审核失败！" + res.data.msg);
              this.$message.success("审核成功！");
              this.getWeighList();
              this.verifiedVisible = !this.verifiedVisible;
              this.comment = '';
            })
          },
            //点击添加提货单
            editWeighDialog(item) {
                this.editWeighVisible_2 = !this.editWeighVisible_2;
                this.editWeighForm = {
                    weighId: item.weighId,
                    weighCar: item.weighCar,
                    weighDelivery: item.weighDelivery,
                    weighAccept: item.weighAccept,
                    weighDriver: item.weighDriver,
                    weighGoods: item.weighGoods,
                    weighPrice: item.weighPrice,
                    weighFirst: item.weighFirst,
                    weighSecond: item.weighSecond,
                    weighLast: item.weighLast,
                    weighSpec:item.weighSpec,
                    verified: item.verified
                }
            },
            display_form() {
                this.form_1 = true;
                this.form_2 = false;
            },
            print() {
                this.$print(this.$refs.print)
            },
          no_print() {
            this.$message.warning("请先审核，再打印！");
          },
          closeDialog() {
            this.$refs.editWeighFormRel.resetFields();
          },
          closeHistoryDialog() {
            this.$data.histories = [];
          },
          closeReviewDialog() {
            this.$data.comment = "";
          },
          showHistory(item) {
            this.historyVisible = !this.historyVisible;
            this.$http.get('historyList/' + item.weighId).then(res => {
              if (res.data.code !== 200) return this.$message.error("查询失败！");
              this.histories = res.data.data;
            })
          },
          deleteWeigh(id) {
              console.log("id: ", id)
              this.$confirm('是否删除称重单?', '警告', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
              }).then(() => {
                  this.$http.post(`weigh_delete/${id}`)
                      .then(res => {
                          if (res.data.code !== 200) return this.$message.error('删除失败！' + res.data.msg);

                          this.$message.success('删除成功！');

                          //刷新列表
                          this.getWeighList();
                      })
              });
          },
          showUnReview(item) {
            this.$http.get('unReviewList/' + item.weighId).then(res => {
              if (res.data.code !== 200) return this.$message.error("查询失败！");
              this.unReviewList = res.data.data;
            })
          },
          previewPic(data) {
            let d = data.split(',');
            return this.imageUrl + d[0];
          },
          previewPicList(data) {
            let d = data.split(',');
            let res = [];
            for (let i = 0; i < d.length; i++) {
              res[i] = this.imageUrl + d[i];
            }
            return res;
          },
          getIcon(history) {
            let icon = '';
            if(history.isReview == 0) {
              return 'el-icon-check'
            }
            if (history.reviewData == null) {
              return icon
            }
            if (history.reviewData.status == 0) {
              icon = ''
            }else if(history.reviewData.status == 1) {
              icon = 'el-icon-check'
            }else if(history.reviewData.status == 2) {
              icon = 'el-icon-close'
            }
            return icon
          },
          getColor(history) {
            let color = '';
            if(history.isReview == 0) {
              return '#42B7FB';
            }
            if (history.reviewData == null) {
              return '';
            }
            if (history.reviewData.status == 2) {
              color = '#F56C6C'
            } else if (history.reviewData.status == 1) {
              color = '#67C23A'
            }else {
              color = '';
            }
            return color
          },
          getStatus(history) {
            let status = '无需审核';
            if (history.isReview == 0) {
              return status;
            }
            if (history.reviewData == null) {
              return '待审核'
            }
            if (history.reviewData.status == 0) {
              status = '待审核'
            } else if (history.reviewData.status == 1) {
              status = '审核通过'
            }else if (history.reviewData.status == 2)  {
              status = '审核不通过';
            }
            return status
          },
          downloadExcel() {
            this.$http.post('downloadExcel', this.queryCondition, {responseType: 'blob'}).then(res => {
              console.log(res)
              if (res.status !== 200) return this.$message.error("导出失败！");
              this.recvExcel("过磅单报表", res.data);
            })
          },
          recvExcel(fileName, data) {
            // const blob = new Blob([data], { type: 'application/vnd.ms-excel' })
            const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            console.log('downloadExcel', blob.size)
            // 创建一个超链接，将文件流赋进去，然后实现这个超链接的单击事件
            const elink = document.createElement('a')
            elink.download = decodeURIComponent(fileName)
            elink.style.display = 'none'
            elink.href = URL.createObjectURL(blob)
            document.body.appendChild(elink)
            elink.click()
            URL.revokeObjectURL(elink.href) // 释放URL 对象
            document.body.removeChild(elink)
          },
          handleSelectionChange(val) {
              this.selection = val;
          },
          batchDelete() {
              this.$confirm('删除后无法恢复，是否继续?', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
              }).then(() => {
                  let ids = this.selection.map((item) => item.weighId);
                  this.$http.post('weigh_batch_delete', ids)
                      .then(res => {
                          if (res.data.code !== 200) return this.$message.error('删除失败！' + res.data.msg);

                          this.$message.success('删除成功！');

                          //刷新列表
                          this.getWeighList();
                      })
              }).catch(action => {
                  if (action === "cancel") {
                      this.$refs.multipleTable.clearSelection();
                      this.selection = [];
                  }
              });
          }

        },
        watch: {
            editWeighForm: {
                handler(newVal) {
                  this.editWeighForm.weighLast = Math.abs(newVal.weighSecond - newVal.weighFirst)
                },
                deep: true
            }
        }

    }
</script>

<style>
    .btn_printer {
      /*display: flex;*/
      /*float: right;*/
      /*margin-bottom: 0px;*/
      position: absolute;
      bottom: 330px;
      right: 65px;
    }
    .btn_printer .el-button {
      width: 120px;
      font-size: 16px;
      font-weight: bolder;
    }

    .show_1 {
        display: none;
    }

    .show_2 {
        display: none;
    }

    .show_3 {
      display: none;
    }

    /*.print_form {*/
    /*    border: 1px solid black;*/
    /*    top: 7%;*/
    /*    position: absolute;*/
    /*    bottom: 0;*/
    /*    width: 100%;*/
    /*    height: 480px;*/
    /*    padding-bottom: -20px;*/
    /*    box-sizing: border-box;*/
    /*}*/

    .print_form {
      border: 1px solid black;
      width: 1060px;
      height: 510px;
      margin-top: 35px;
      padding-left: 10px;
    }

    .print_form .el-form-item {
        font-weight: bold;
    }

    .print-time {
        margin-left: 17px;
        color: black;
        font-weight: bolder
    }

    .title {
      text-align: center;
      font-size: 24px;
      font-weight: bolder;
      letter-spacing: 5px;
    }
    .primary {
      font-size: 16px;

      font-weight: 500;
      color: #42B7FB;
    }
    .separator {
      color: #ccc;
      padding: 0 5px;
    }
    .search_area {
      float: right;
      margin-bottom: 20px;
    }
    .el-breadcrumb {
      font-size: 14px;
      display: inline;
      float: left;
      margin: 15px 0 0 5px
    }
    /deep/ .history .el-dialog__body{
      padding-top: 0;
    }
    .print_item {
      color: black;
      font-size: 18px;
    }
    .w2 {
      letter-spacing: 2.0em;
    }
    .w3 {
      letter-spacing: 0.5em;
      margin-right: -0.5em;
    }
    .print_col {
      margin-left: 55px
    }
    .print_col_bottom {
      margin-left: 50px
    }
    .kg {
      color: black;
      font-style: normal;
      font-weight: bolder;
      margin-right: 8px
    }
</style>
<style scoped>
/deep/.print_form .el-input__inner {
  font-size: 18px;
  color: black;
  border: 3px solid #000;
  border-radius: 0;
}
/deep/.el-table .cell:empty::before {
  content: "—";
  color: #999;
}
.danger {
    font-size: 16px;
    font-weight: 500;
    color: #ff4d51;
}
</style>
